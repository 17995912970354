/**/

.titleContainer {
  margin: 0 auto;
  text-align: center;
}
.title {
  font-size: 1.5rem;
  color: $small-heading-color;
  text-transform: uppercase;
  font-weight: 300;
  position: relative;
  margin-bottom: 3rem;
}
.title::before,
.title::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.75);
}

.title::before {
  width: 10rem;
  height: 3px;
  border-radius: 0.8rem;
}

.title::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.2rem;
}

.jobs-section {
  padding: 5rem 0;
  color: $small-heading-color;
}
.jobs-center {
  width: 80vw;
  margin: 0 auto;
  max-width: 1170px;
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4rem;
}

.job-btn {
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  transition: all 0.3s linear;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  color: $heading-color;
}

.job-btn:hover {
  color: $highlight-color;
  box-shadow: 0 2px $highlight-color;
  background: $dark-background;
}
.active-btn {
  color: $highlight-color;
  box-shadow: 0 2px $highlight-color;
  background: $dark-background;
}

.job-info h3 {
  font-weight: 400;
}
.job-info h4 {
  text-transform: uppercase;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}
.job-date {
  letter-spacing: 0.2rem;
}
.job-desc {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2rem;
  align-items: center;
  margin-bottom: 1.25rem;
}
.job-desc p {
  margin-bottom: 0;
  color: $heading-color;
}
.job-icon {
  color: $heading-color;
}
@media screen and (min-width: 992px) {
  .jobs-center {
    width: 90vw;
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 4rem;
  }
  .btn-container {
    flex-direction: column;
    justify-content: flex-start;
    border-left: 2px solid $light-background;
  }
  .job-btn {
    margin-bottom: 1rem;
  }
  .active-btn {
    box-shadow: -2px 0 $highlight-color;
  }
  .job-btn:hover {
    box-shadow: -2px 0 $highlight-color;
  }
}

@media only screen and (max-width: 500px) {
  .employer {
    font-size: 0.8rem;
  }
}
