/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
  background-color: $background;
}

label {
  font-size: 0.9rem;
}
