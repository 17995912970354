$font-family-base: "Poppins", Helvetica, sans-serif;
$background: #2a2a2a;
$font-family-title: Roboto, Poppins, Nunito Sans, sans-serif;
$medium-font-size: 14px;
$dark-grey: #333;
$highlight-color: #64ffdb;
$heading-color: #8a8a8a;
$small-heading-color: #cacaca;
$light-background: #383838;
$dark-background: #333232;
