.summary-container {
  margin: 2rem 15rem 0 15rem;

  h1 {
    color: $highlight-color;
    font-size: 1rem;
  }

  h2 {
    color: $small-heading-color;
    font-size: 3rem;
  }

  div {
    color: $heading-color;
    font-size: 2rem;
  }

  a {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: $background;
    text-decoration: none;
    margin-top: 2rem;
    margin-right: 85%;
    padding: 1.5rem 2rem;
    color: rgb(100, 255, 218);
    border: 1px solid $highlight-color;
    border-radius: 5px;
    transition: background-color 650ms;
  }

  a:hover {
    background-color: r$background;
  }
}

@media screen and (max-width: 1100px) {
  .summary-container {
    margin: 2rem 12rem 0 12rem;

    a {
      margin-right: 50%;

      padding: 1.5rem 0.5rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .summary-container {
    margin: 2rem 10rem 0 10rem;
    padding-top: 5rem;
  }
}

@media screen and (max-width: 500px) {
  .summary-container {
    margin: 2rem 1rem 0 5rem;

    a {
      margin-right: 40%;
      margin-left: 20%;

      padding: 1rem 0.5rem;
      font-size: 0.7rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    div {
      font-size: 1rem;
    }
  }
}
