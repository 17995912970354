.contact-container {
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $small-heading-color;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    color: $heading-color;
  }
}

//Begin social media
.nav-items {
  margin: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $highlight-color;
}

.nav-items:hover {
  cursor: pointer;
}

.nav-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 1.5rem;
}
.nav-item {
  list-style: none;
  margin-left: 2rem;
}

.nav-link {
  color: $highlight-color;
  font-size: 1.5rem;

  i {
    transition: color 1000ms;
  }
}

.nav-link:hover {
  i.fa-linkedin {
    color: #0077b5;
  }

  i.fa-github {
    color: #ad5c51;
  }

  i.fa-file-pdf-o {
    color: yellowgreen;
  }
}

//End social media

.mail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $background;
  text-decoration: none;
  margin: 2rem 45% 2rem 45%;

  padding: 1.5rem 0;
  color: $highlight-color;
  border: 1px solid $highlight-color;
  border-radius: 5px;
  transition: background-color 650ms;
}

.mail:hover {
  background-color: rgba(100, 255, 218, 0.07);
}

//Chevron button
.up {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: #eee;
  //top: -2.5rem;
  right: 5rem;
  border-radius: 100%;
  display: flex;
  animation: pulse 2s infinite;
  cursor: pointer;
}

.icon {
  color: #16162d;
  font-size: 1.2rem;
  margin: auto;
}

//

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 87, 191, 0.99);
  }
  70% {
    box-shadow: 0 0 0 2rem rgba(253, 87, 191, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(253, 87, 191, 0.99);
  }
}

@media only screen and (max-width: 1100px) {
  .contact-container {
    margin-top: 4rem;
  }
  .contact-container a {
    margin: 2rem 38% 2rem 38%;
  }
}

@media only screen and (max-width: 800px) {
  .contact-container {
    margin: 2rem 0 0 2rem;
  }

  .contact-container a {
    margin: 2rem 30%;
  }

  .up {
    display: none;
    width: 2rem;
    height: 2rem;
  }

  .icon {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .contact-container {
    margin: 2rem 0 0 2rem;
  }
  .contact-container a {
    margin: 2rem 6rem 2rem 4rem;
  }

  .up {
    display: none;
    width: 2rem;
    height: 2rem;
  }

  .icon {
    font-size: 0.8rem;
  }

  .nav-items {
    width: 65%;
    padding-left: 3rem;
  }

  .nav-item {
    margin-left: 0;
  }

  .nav-list {
    padding-right: 0rem;
  }
}

@media only screen and (max-width: 400px) {
  .nav-items {
    width: 58%;
    padding-left: 2rem;
  }
}
